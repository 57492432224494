<template>
  <div class="p-root">
    <img :src="banner" class="full-width" />

    <div class="full-width column-center" style="padding: 50px 0">
      <span class="intro-title">Préface</span>
      <!-- <span class="intro-title">前 言</span> -->
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-red" style="font-size: 22px; margin-top: 30px">An international dialogue between art,
        literature and children</span>
      <span class="intro-red" style="font-size: 22px">A spiritual conversation across time, space, age and
        identity</span>

      <span class="intro-content-width intro-18" style="margin-top: 20px">On the 150th anniversary of the birth of
        Marcel Proust, an eminent novelist in the 20th century, Castle Swan in Proust’s hometown has been opened to the
        public, which unveils the mystery of his masterpiece, Remembrance of Things Past. This stunning castle, with its
        lush gardens and mysterious chapel, was the source of the writer’s creativity. Its opening has aroused great
        interest among literary amateurs and the public. Even the French President Macron also presented here, with a
        cheque for 400,000 euros and a congratulatory speech. He claimed that “here, literature occupies every space and
        resides in our lives.” As a result, the media scrambled to cover the story, and amounts of people came here to
        investigate the mystery of the castle. On September 18, European heritage day, five international artists
        (painters) gathered here to create five “unfinished” works based on the beautiful scene of Castle Swan and
        memories of the writer’s childhood. These five “unfinished paintings,” with the blessings and expectations of
        the artists, will fly to China and other countries around the world to open a mysterious and interesting
        dialogue with the children there! The aim of this art project was to allow the children to play with their wild
        imaginations and innocent artistic talents. Each of the artists’ unfinished works had hidden “gaps” that the
        children had to find and fill. Each child was required to interpret the artist’s ingenuity, and then complete
        the five paintings with their innocent and wild ideas. Of course, the artists would also send video tips and
        interpretations remotely throughout the process to guide the children through the process. Ultimately, a jury of
        Chinese and foreign artists will select the 1,000 works that resonate with them. These 1,000 paintings will be
        publicly displayed in an exhibition dedicated to the 100th anniversary of Marcel Proust’s death (1922-2022) at
        the Louvre and the Château de Swan. They will also reveal the final version of the “complete paintings” there.
        Picasso has once said that “every child is a born painter.” When these “born artists” meet with successful
        artists, what magical sparks and excellent works will emerge from the heart-to-heart exchange between East and
        West, master and child?</span>
      <!-- <span class="intro-content-width intro-18"
        style="margin-top: 20px">这一切引起无数文学爱好者乃至全社会的极大兴趣。法国总统马克龙也匆匆赶来，送上一张40万欧元的支票，和热忱的祝词：“在这里，文学占领了各个空间，并驻入我们的人生。”于是，各大媒体争相报道，众多民众也前来一探究竟。</span>

      <span class="intro-black intro-content-width intro-18" style="margin-top: 30px">国际成功艺术家与世界儿童联合创作</span>
      <span class="intro-content-width intro-18"
        style="margin-top: 20px">9月18日欧洲遗产日，五位国际艺术大师（画家）赶来，以美丽的斯万城堡为原型，以作家儿时的回忆为元素，创作五幅“未完成”作品。而这五幅未完成的画作，将带着五位艺术家的祝福与期待，飞往中国及世界各国，与那里的孩子们开启一场神秘而有趣的对话!</span>
      <span class="intro-content-width intro-18"
        style="margin-top: 20px">这也正是本次艺术联创的奥妙所在，让孩子们在游戏中发挥各自的烂漫想象力和天真质朴的艺术才华。原来，艺术家们的每幅（未完成）作品都隐藏有各自留下的“空白”，需要孩子们寻找并填补完成。每位儿童需要揣摩、解读艺术家的巧思，同时也大胆加入自己天马行空的想法，完成五幅画作。当然，整个过程中艺术家们也会远程发来视频提示和诠释，指导各位孩子们完成创作。最终，中外艺术家组成的评审团将会选出最能与他们产生共鸣的1000幅作品，在法国艺术圣地卢浮宫和斯万家的城堡，举办纪念马赛尔·普鲁斯特逝世100周年(1922—2022)的主题画展，公开展示这1000幅孩子们的画作，同时他们也将公开自己最终版的“完整画作”，</span>
      <span class="intro-content-width intro-18" style="margin-top: 20px">
        <span
          class="intro-black">毕加索说，每个孩子都是天生的画家。</span>当这些“天生的艺术家”遇到了已经成功的艺术家，东方与西方，大师与孩子们隔空的心灵交流，会产生怎样神奇的令人惊喜的火花，以及伟大的作品?</span> -->
    </div>

    <div class="full-width column-center" style="background-color: #f5efe6; padding: 50px 0">
      <span class="intro-title">Participants</span>
      <!-- <span class="intro-title">报名者年龄</span> -->
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-red" style="font-size: 22px; font-weight: bold; margin-top: 30px"> Children aged 3-14 years are
        divided into five age groups</span>
      <span class="intro-black intro-18" style="margin-top: 10px">3-5 years, 6-7 years, 8-9 years, 10-12 years and 13-14
        years</span>
    </div>

    <div class="full-width column-center" style="background-color: #e0cfb8; padding: 50px 320px">
      <span class="intro-title">Thème</span>
      <!-- <span class="intro-title">巡展主题</span> -->
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />
      <span class="intro-red" style="font-size: 22px; font-weight: bold; margin-top: 30px">Remembrance of Things Past -
        Mystery of Swan</span>
    </div>

    <div class="full-width column-center" style="background-color: #072736; padding: 50px; color: white">
      <span class="intro-title">Tour Schedule</span>
      <!-- <span class="intro-title">巡展日程</span> -->
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-18" style="margin-top: 30px"> Registration and submission of entries
      </span>
      <span class="intro-red intro-18" style="font-weight: bold">July 15,2022 – June10,2023</span>

      <span class="intro-18" style="margin-top: 30px"> Announcement date of the French tour and special award</span>
      <span class="intro-18">July ,2023</span>
      <!-- <span class="intro-18"> Form of announcement: Organizing committee and registration agencies</span>

      <span class="intro-18" style="margin-top: 30px"> Announcement of the results of the review (Gold Award / Un
        Certain Regard)</span>
      <span class="intro-18">January 18, 2022</span>
      <span class="intro-18"> Form of announcement: Organizing committee and registration agencies</span>

      <span class="intro-18" style="margin-top: 30px"> Announcement date of the French tour and special award</span>
      <span class="intro-18">February 2022</span> -->
    </div>

    <div class="full-width column-center" style="padding: 50px 320px">
      <span class="intro-title">L ’unité Unit Setup</span>
      <!-- <span class="intro-title">单元设置</span> -->
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-red intro-18" style="font-weight: bold; font-size: 18px; margin-top: 30px"> Main
        Competition</span>
      <span class="intro-content-width intro-18" style="margin-top: 10px">·
        Each of the five international artists creates one “unfinished work.” Participants will be able to see clear
        pictures of their works on the official website or in the “Co-creative Artists” section of WeChat Subcription.
        Upon completion of the application form and payment of the fee, the organizers will provide a high resolution
        electronic copy of the international artist’s “unfinished works” by email or by directly contacting the
        applicant. Applicants can choose one of these works to begin their creation. Applicants can select the
        “unfinished” work according to their ages. It is permissible to create the painting in its original, enlarged or
        reduced version. Children can choose to finish in the middle or around the original painting. There is only one
        requirement that the work must be created independently by the entrant aged 3-14. The five artists will continue
        to complete the “unfinished” work, which will be exhibited alongside exhibitors’ work at the Louvre in
        France.</span>
      <!-- <span class="intro-content-width intro-18">·
        报名者按年龄选择对应“未完成”作品，继续创作。这一创作可以是在原大作品尺寸创作，也可以将“未完成”作品放大或缩小，
        在其中间或周围围绕完成，但创作必须是3-14岁报名者自己独立完成的。</span>
      <span class="intro-content-width intro-18">·
        5位艺术家将继续完成该“未完成”作品，完成作品将在法国卢浮宫画展时与参展者的作品一同展出。</span> -->
      <!-- <span style="text-align: right; text-decoration: underline; cursor: pointer" class="intro-content-width"
        @click="goArtists">点击查看如何创作</span> -->

      <!-- <span class="intro-blue intro-18" style="font-weight: bold; font-size: 18px;margin-top:50px">Little Maupassant
        Creativity
        Unit</span>
      <span class="intro-content-width intro-18" style="margin-top: 10px">As the chief strategic partner of the
        “Normandie 1905” brand range, the “Petit Maupassant Children’s Milk” will be awarded the “Petit Maupassant
        Creativity Award”. All entrants will receive a carton of “Little Maupassant” Children’s Milk according to the
        address left by the entrant after registering on the website and on WeChat Subcription. Entrants will dismantle
        the milk carton and create an artwork. They can either paint the box or dismantle it, cut it out and create a
        collage or stickers.</span>
      <span class="intro-content-width" style="text-align: right; text-decoration: underline; cursor: pointer"
        @click="goMilk">Click here to view “Petit Maupassant Milk”</span> -->
    </div>

    <div class="full-width column-center" style="background-color: #f5efe6; padding: 50px 320px">
      <span class="intro-title">Awards</span>
      <!-- <span class="intro-title">奖项荣誉</span> -->
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-content-width intro-red"
        style="font-size: 22px;font-weight: bold;text-align: center;margin-top: 30px;">[Main Competition Unit]
        Preliminary Selection (to be announced on January 5) for the "Little Maupassant Award of Excellence"</span>
      <div class="column intro-18" style="margin-top: 10px">
        <span class="intro-content-width">· Number of finalists: more than 90% of the works</span>
        <span class="intro-content-width">·Selection criteria: The organizing committeewill select the works that do not
          meet the requirements of the
          exhibition and submit them to the organizing committee for a second selection stage. (The work of directly
          registration on the official website and WeChat Supscrition is selected by the organizing committee itself.
          )</span>
        <span class="intro-content-width">· The award Prizes:</span>
        <span class="intro-content-width">
          1）a “Little Maupassant Award of Excellence” certificate signed electronically by the international
          artist;</span>
        <span class="intro-content-width">
          2）a chance to win the “Un Certainty Award” and the “Little Maupassant Award of Excellence” if they are
          selected by the organizing committee. </span>
        <span class="intro-content-width"> 3）Exhibition in the “Online Showroom” on the website.</span>
      </div>
      <span class="intro-content-width intro-red"
        style="font-size: 22px;font-weight: bold;text-align: center;margin-top: 30px;">[Main Competition Unit] All works
        exhibited in the “Online Gallery” will have the opportunity to win the “Socially Noteworthy Award”.</span>
      <div class="column intro-18" style="margin-top: 10px">
        <span class="intro-content-width">· Number of finalists: unlimited</span>
        <span class="intro-content-width">·
          Selection Criteria: Works will be uploaded to the “Online Exhibition Hall” whenever they are received by the
          Organizing Committee, and by October 2022, accomplished “international and domestic community members” will
          be invited to select their favorite works from among the reviewed works and write a “Recommendation”. “The
          selection process will be completed by October 2022, with the selection of the works to be reviewed and a
          recommendation written.</span>
        <span class="intro-content-width">· The award Prizes:</span>
        <span class="intro-content-width"> 1）a touring exhibition in France</span>
        <span class="intro-content-width">
          2）a certificate of the Un Certain Regard Award with the recommendation of the “accomplished member of
          society”.</span>
      </div>
      <span class="intro-content-width intro-red"
        style="font-size: 22px;font-weight: bold;text-align: center;margin-top: 30px;">[Main Competition] Review to
        receive the "Little Maupassant Gold Award"</span>
      <div class="column intro-18" style="margin-top: 10px">
        <span class="intro-content-width">· Number of finalists: 1,000+</span>
        <span class="intro-content-width">· Selection criteria: the organizing committee will upload the works to the
          “official website” and invite a “jury of Chinese and French artists” to select the gold medal among the
          reviewed works.</span>
        <span class="intro-content-width">· Prizes:</span>
        <span class="intro-content-width"> 1）a touring exhibition in France</span>
        <span class="intro-content-width"> 2）the “Petit Maupassant Gold Medal”</span>
        <span class="intro-content-width">Note: the “Un Certain Regard” and “Petit Maupassant Gold Medal” works that are
          eligible for the touring exhibition can If you choose to participate in the exhibition, you will be required
          to pay an international exhibition fee of RMB 1,380 per work.</span>
        <span class="intro-content-width">
          1）Certificate of Louvre Exhibition issued by France-China Exchange Promotion Association;</span>
        <span class="intro-content-width">
          2）Electronic version of works on tour exhibition in France provided by the organizing Committee;</span>
        <span class="intro-content-width">
          3）Works included in the album of International Art Creation Tour Exhibition;</span>
        <span class="intro-content-width">
          4）Works and author information have a chance to appear in Chinese and foreign media reports</span>
        <span class="intro-content-width">
          5）A chance to win the “Special Prize” (50) chosen at the exhibition in the Louvre</span>
      </div>
      <!-- <span class="intro-content-width intro-blue"
        style="font-size: 22px;font-weight: bold;text-align: center;margin-top: 30px;">[Creativity Unit] “Little
        Maupassant Creativity Award”</span>
      <div class="column intro-18" style="margin-top: 10px">
        <span class="intro-content-width">· Number of finalists: 100</span>
        <span class="intro-content-width">· Selection criteria: selected by the “Normandie 1905” brand</span>
        <span class="intro-content-width">· Prizes:</span>
        <span class="intro-content-width"> 1） a “Little Maupassant creativity medal”</span>
        <span class="intro-content-width">
          2）a booklet of their work and a booklet for the author of the best work</span>
        <span class="intro-content-width"> 3）the right to have their work exhibited in France</span>
        <span class="intro-content-width">
          4）a “Little Maupassant creativity ambassador” from the “Nomandi 1905” brand</span>
        <span class="intro-content-width">
          5）The opportunity to be invited to experience life on a seaside ranch in Cotentin, France for 7-10 days.
          (Note: The organizers will issue an invitation letter to cover the accommodation and food of one of the
          winning authors during their stay in France. (This entry is subject to availability depending on the epidemic
          situation)</span>
      </div> -->
      <span class="intro-black" style="font-size: 22px; font-weight: bold; margin-top: 30px">Touring Exhibition in
        France</span>
      <div class="column intro-18" style="margin-top: 10px">
        <span class="intro-content-width">· Time: April – May,2023</span>
        <span class="intro-content-width">· Location: Louvre, Paris, Chateau Marcel Proustswan, France</span>
        <span class="intro-content-width">· Note: Works that have won the Un Certain Regard Award and the Petit
          Maupassant Gold Award are entitled to participate in the French touring exhibition. If you choose to
          participate in the exhibition, you will need to pay an additional “international exhibition fee of RMB 1,380
          per work. In the French touring exhibition, the five artists will also announce their “complete paintings” and
          exhibit them together.</span>
      </div>
    </div>

    <div class="full-width column-center" style="background-color: #e0cfb8; padding: 50px 320px">
      <span class="intro-title">Description of fees</span>
      <!-- <span class="intro-title">费用说明</span> -->
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-red" style="font-size: 22px; font-weight: bold; margin-top: 30px"> Materials fee (pay at the
        time of registration) </span>
      <span class="intro-red" style="font-size: 20px; margin-top: 10px">RMB 300 per entry</span>
      <span class="intro-content-width intro-18" style="margin-top: 10px">The registration form must be completed and
        this materials fee must be paid at the time of registration, which includes: handling fee, translation fee,
        international contact fee, judging fee, shipping fee, etc.</span>
      <!-- <span class="intro-content-width">Note: Participants can participate in both the [Main Competition] and
        [Creativity] sections at the same time, with one entry corresponding to one material fee.</span> -->

      <span class="intro-red" style="font-size: 22px; font-weight: bold; margin-top: 30px"> International exhibition fee
        (payable after passing the review) </span>
      <span class="intro-red" style="font-size: 20px; margin-top: 10px">RMB 1,380 per work</span>
      <span class="intro-content-width intro-18" style="margin-top: 10px">After the announcement of the results of the
        review on January, 2023, the winners of all the Units will be able to choose whether or not to participate in
        the traveling exhibition at the Louvre and Château de Swan. The works that have been awarded the Un Certain
        Regard Prize and the Petit Maupassant Gold Prize will be eligible for participation in the traveling exhibition
        at the Louvre and Château de Swan. This fee is payable for works selected for exhibition.</span>
    </div>

    <div class="full-width column-center" style="padding: 50px 320px">
      <span class="intro-title">Notes for participation</span>
      <!-- <span class="intro-title">参加须知</span> -->
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <div class="row intro-content-width intro-18" style="align-items: flex-start; margin-top: 30px">
        <div class="intro-black" style="font-weight: bold">·Detailed process:</div>
        <div style="flex: 1">
          Applicants will receive a high resolution image of the unfinished work of the international painter after
          registering through the “official website” or “WeChat Subscription” or “offline partner organisations”. After
          the completion of the work, the original work will be delivered to the group. Once you have completed your
          work, send the original work by courier to the organizing committee (see “Contact Us” on this website).
        </div>
      </div>
      <div class="row intro-content-width intro-18" style="align-items: flex-start; margin-top: 10px">
        <div class="intro-black" style="font-weight: bold">·Type of work:</div>
        <div style="flex: 1">
          Ink and wash, watercolor painting, gouache, oil, crayon, drawing, cartoon, caricature, colored pencil,
          printmaking, collage, pen and pencil, composite painting and other types of work are all eligible for
          exhibition. (Note: digital paintings and other electronic works cannot be exhibited)
        </div>
      </div>
      <div class="row intro-content-width intro-18" style="align-items: flex-start; margin-top: 10px">
        <div class="intro-black" style="font-weight: bold">·Specifications:</div>
        <div style="flex: 1">
          Thick paper, size 29.7cm*42cm or 26.5cm*38cm. Works that do not meet the size are
          considered invalid.
        </div>
      </div>
      <!-- <div class="row intro-content-width intro-18" style="align-items: flex-start; margin-top: 10px">
        <div class="intro-black" style="font-weight: bold">·Number of works:</div>
        <div style="flex: 1">
          Exhibitors can participate in both the [Main Competition Unit] and [Creativity Unit]. 1 entry form for each
          work + documentation fee. If both works are qualified for the touring exhibition, two copies of the
          international exhibition fee will be required. Exhibitors are limited to one work per section.
        </div>
      </div> -->
      <div class="row intro-content-width intro-18" style="align-items: flex-start; margin-top: 10px">
        <div class="intro-black" style="font-weight: bold">·Requirements:</div>
        <div style="flex: 1">
          Participants in the main competition can choose one of the “unfinished works by international painters” to
          continue their work. Only works created by the artist are allowed to be exhibited, not collaborative or
          cooperative works. Fragile, uneven and uneven shapes are not allowed. No insect-prone grains or potentially
          perishable or fragile materials are allowed.
        </div>
      </div>
    </div>

    <!-- <div class="full-width column-center" style="background-color: #f5efe6; padding: 50px 320px">
      <span class="intro-title">Application</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <div class="row intro-content-width" style="margin-top: 30px; align-items: flex-start">
        <div class="column" style="width: 400px">
          <span class="intro-red" style="font-size: 22px; font-weight: bold">Method 1: Register directly on the official
            website</span>
          <span class="intro-18">·Click on “I want to register” on the official website to register.</span>
          <span class="intro-18">·You can also register on the WeChat Subscription and follow for more competition
            real-time information notification.</span>
        </div>
        <div class="column" style="width: 400px;margin-left:20px">
          <span class="intro-red" style="font-size: 22px; font-weight: bold">Method 2: Register at offline partner
            organizations</span>
          <span class="intro-18">·Applicants can register at offline partner organizations of the event</span>
          <span class="intro-18">·the registration information will be summarized by each registration point and
            submitted to the organizing committee</span>
        </div>
      </div>
      <div class="row intro-content-width" style="align-items: flex-start; margin-top: 30px">
        <img :src="icons.footer_qr_code" style="width: 160px" />
        <span class="intro-18" style="margin-top: 30px; margin-left: 80px">Note: There are 2 ways to register for this
          event, you can choose either one of the above, no need to repeat registration</span>
      </div>
    </div> -->

    <div class="full-width" style="background-color: #2588ce; height: 20px"></div>

    <div class="full-width column-center" style="background-color: #072736; padding: 50px; color: white">
      <span class="intro-title">Statement of Rights and Interests of Droits Work</span>
      <!-- <span class="intro-title">作品权益声明</span> -->
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <div class="row intro-content-width" style="justify-content:center;font-size: 24px; margin-top: 30px">
        Intellectual property rights and related statements of the works participating in the first “Little Maupassant
        Cup” International Art Joint Tour Exhibition</div>
      <!-- <div class="row intro-content-width" style="justify-content:center;font-size: 24px">参展作品知识产权及相关声明</div> -->

      <div class="row intro-18 intro-content-width" style="margin-top: 40px;">
        I. The copyright protection for the first “Little Maupassant Cup” International Art Joint Tour Exhibition
        (hereinafter referred to as “Joint Activities”) is carried out in accordance with the Copyright Law of the
        People's Republic of China, Rules for the Implementation of the Copyright Law of the People’s Republic of China
        and other relevant laws.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        II. If the exhibitors cannot participate in Joint Activities for personal reasons after they sign up
        successfully and pay the materials fee, the materials fee shall not be refunded.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        III. Exhibitors must obtain the consent of their guardians. Exhibitors or their guardians have known, confirmed
        and agreed to strictly abide by the rules of the draft notice of the Joint Activities (including the
        intellectual property rights and relevant statements of participating works).
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        IV. The works submitted by exhibitors shall be original works and created independently by themselves.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        V. All graphic materials submitted by exhibitors shall be true and effective and shall not infringe the
        intellectual property rights, portrait rights, privacy rights, reputation rights or other legitimate rights and
        interests of the third party. Otherwise, they will be disqualified from participating in the exhibition, and
        bear the relevant legal consequences.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        VI. Without the consent of the sponsor, any third party shall not use any information and images of exhibitors
        and their exhibition works in any form. The binding condition shall not be terminated due to the end of Joint
        Activities.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        VII. Exhibitors shall not transfer the exhibition works or permit the third party to use them during the
        exhibition. The exhibition works shall not be used for commercial purposes without the written consent of the
        sponsor of Joint Activities.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        VIII. In case of any infringement of copyright or other intellectual property rights due to the negligence and
        intention of the exhibitors, which leads to any loss and damage to the sponsor, the exhibitors shall be fully
        responsible for it.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        IX. The ownership of the exhibition works belongs to the Association pour le Développement des échanges
        France-Chinois (hereinafter referred to as “the Association pour le Développement des échanges France-Chinois”)
        or the designated party, and the exhibition works shall not be returned. If the exhibition works are
        subsequently successfully auctioned or sold, the net proceeds from the auction or sale of the exhibition works
        shall be shared by 50% for the Association pour le Développement des échanges France-Chinois, 15% for the
        special artists participating in the joint activities of the exhibition works (one of Malgorzata Paszko/
        Christophe Ronel/Emmanuelle Renard/Sophie Sainrapt/Augusto Foldi) and 35% for the exhibitors participating in
        the exhibition works (excluding tax and commission).
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        X. In addition to the right of authorship of the exhibition works, exhibitors enjoy other copyright of the
        exhibition works, including but not limited to the reproduction right, distribution right, exhibition right,
        performance right, projection right, information network communication right, production right, adaptation
        right, translation right, compilation right and derivative copyright of the exhibition works, which are owned by
        Association pour le Développement des échanges France-Chinois or the designated party.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        XI. In case of the following infringement acts during joint activities, the infringer shall bear civil
        liabilities according to the circumstances, such as stopping the infringement, eliminating the impact, making an
        apology and compensating for losses; in case of damage to the public interest, the copyright administration
        department may order him to stop the infringement, confiscate the illegal income, confiscate and destroy the
        copies, and impose fine; in case of a crime due to serious circumstances, he shall be investigated for the legal
        responsibility according to law:
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;; margin-left: 30px">
        1. Using his works for exhibition without the permission of the owner of the copyright;
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;; margin-left: 30px">
        2. Exhibiting others' works as their own works without the permission of other authors;
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;; margin-left: 30px">
        3. Distorting, altering or plagiarizing others' works;
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;; margin-left: 30px">
        4. Other acts that infringe upon copyright and rights and interests related to copyright.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        XII. In case of any non-human factors or other accidents beyond the control of the sponsor of Joint Activities,
        including earthquake, fire, flood, riot, war, strike, epidemic, change of government laws and regulations and
        other force majeure events, the sponsor of the Joint Activities has the right to cancel, postpone or adjust
        Joint Activities and relevant specific arrangements accordingly.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        XIII. Exhibitors and their guardians have carefully read the statement. Once they participate in any or all of
        the Joint Activities, it shall be deemed that exhibitors and their guardians have fully understood and accepted
        all terms including the statement, and are willing to bear all legal consequences arising from the statement.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        XIV. The Association pour le Développement des échanges France-Chinois or the designated party reserves the
        right of final interpretation of the rules of Joint Activities.
      </div>
    </div>
  </div>
</template>

<script>
import * as eventHelper from "@/utils/eventHelper";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      icons: this.$root.icons,

      banner: "",
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);

    this.getBanner();
  },
  methods: {
    ...mapActions({
      positionGetContentAction: "positionGetContent",
    }),
    goArtists() {
      // this.$router.push({
      //   name: "artists",
      // });
      this.$router.push({
        name: "create",
      });
    },
    goMilk() {
      window.open(
        "https://shop92949538.m.youzan.com/v2/showcase/homepage?alias=eC6Gfn4IzM&dc_ps=2841760543432437765.300001"
      );
    },
    getBanner() {
      this.positionGetContentAction({
        position_id: 7,
      }).then((res) => {
        this.banner = res.img_url;
      });
    },
  },
};
</script>

<style scoped>
.intro-content-width {
  width: 800px;
}
.divide {
  background-color: #ffffff;
  height: 1px;
  width: 500px;
  opacity: 0.5;
}
.intro-title {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  letter-spacing: 0;
  text-align: center;
}
.intro-title-white {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.intro-content-white {
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 35px;
}
.intro-title-blue {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #062c3e;
  letter-spacing: 0;
  text-align: center;
}
.intro-content-white-24 {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.intro-content-blue {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #062c3e;
  letter-spacing: 0;
  line-height: 44px;
}
.intro-title-yellow {
  font-family: ZhenyanGB-Regular;
  font-size: 30px;
  color: #f7eb50;
  letter-spacing: 0;
}
.intro-btn {
  cursor: pointer;
  background: #062c3e;
  border-radius: 8px;
  padding: 0 30px;
  height: 65px;
  align-self: flex-start;
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 65px;
}

.intro-red {
  color: #f72e02;
}
.intro-blue {
  color: #0088cd;
}
.intro-black {
  color: #000000;
}

.intro-18 {
  font-size: 18px;
}
</style>